import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/index.vue'),
    meta: {
      title: 'STARFI'
    }
  },
  {
    path: '/1-stage-evaluation/',
    name: '1-stage-evaluation',
    component: () => import(/* webpackChunkName: "1-stage-evaluation" */ '../views/1-stage-evaluation.vue'),
    meta: {
      title: '1-Stage Evaluation - STARFI'
    }
  },
  {
    path: '/2-stage-evaluation/',
    name: '2-stage-evaluation',
    component: () => import(/* webpackChunkName: "2-stage-evaluation" */ '../views/2-stage-evaluation.vue'),
    meta: {
      title: '2-Stage Evaluation - STARFI'
    }
  },
  {
    path: '/3-stage/',
    name: '3-stage',
    component: () => import(/* webpackChunkName: "3-stage" */ '../views/3-stage.vue'),
    meta: {
      title: '3-Stage - STARFI'
    }
  },
  {
    path: '/contact-us/',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/contact-us.vue'),
    meta: {
      title: 'STARFI - Contact Us'
    }
  },
  {
    path: '/about-us/',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "About-us" */ '../views/about-us.vue'),
    meta: {
      title: 'STARFI - About Us'
    }
  },
  {
    path: '/terms-of-use/',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/terms-of-use.vue'),
    meta: {
      title: 'Terms of use - STARFI'
    }
  },
  {
    path: '/refund-policy/',
    name: 'refund-policy',
    component: () => import(/* webpackChunkName: "refund-policy" */ '../views/refund-policy.vue'),
    meta: {
      title: 'Terms of use - STARFI'
    }
  },
  {
    path: '/aml-guidelines/',
    name: 'aml-guidelines',
    component: () => import(/* webpackChunkName: "aml-guidelines" */ '../views/aml-guidelines.vue'),
    meta: {
      title: 'Terms of use - STARFI'
    }
  },
  {
    path: '/challenges/',
    name: 'challenges',
    component: () => import(/* webpackChunkName: "challenges" */ '../views/challenges.vue'),
    meta: {
      title: 'STARFI'
    }
  },
  {
    path: '/faq/',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/faq.vue'),
    meta: {
      title: 'STARFI'
    }
  },
  // {
  //   path: '/state',
  //   name: 'state',
  //   children: [
  //     {
  //       path: 'alabama',
  //       name: 'alabama',
  //       component: () => import(/* webpackChunkName: "alabama" */ '../views/state/alabama.vue'),
  //       meta:{
  //         title: 'Alabama - American Investment Council'
  //       }
  //     },
  //   ]
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})

export default router
