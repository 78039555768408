<template>
  <footer itemtype="https://schema.org/WPFooter" itemscope="itemscope" id="colophon" role="contentinfo">
      <div class='footer-width-fixer'>
          <div data-elementor-type="wp-post" data-elementor-id="169" class="elementor elementor-169">
              <section class="elementor-section elementor-top-section elementor-element elementor-element-799e4f8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="799e4f8" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <div class="elementor-container elementor-column-gap-default">
                      <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8c45648" data-id="8c45648" data-element_type="column">
                          <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-2db3771 uael-heading-align-center elementor-widget elementor-widget-uael-advanced-heading" data-id="2db3771" data-element_type="widget" data-widget_type="uael-advanced-heading.default">
                                  <div class="elementor-widget-container">

                                      <div class="uael-module-content uael-heading-wrapper">

                                          <h2 class="uael-heading">
                                              <span class="uael-heading-text elementor-inline-editing uael-size--default" data-elementor-setting-key="heading_title" data-elementor-inline-editing-toolbar="basic">Ready To Become</span>
                                          </h2>

                                          <div class="uael-sub-heading elementor-inline-editing" data-elementor-setting-key="sub_heading" data-elementor-inline-editing-toolbar="advanced">Institutional Trading Account?</div>



                                      </div>
                                  </div>
                              </div>
                              <div class="elementor-element elementor-element-b9e5d4c elementor-widget elementor-widget-text-editor" data-id="b9e5d4c" data-element_type="widget" data-widget_type="text-editor.default">
                                  <div class="elementor-widget-container">
                                      <p>No time limits. Zero denied payouts. 100% transparency.</p>
                                  </div>
                              </div>
                              <div class="elementor-element elementor-element-b162ba2 elementor-widget__width-auto button-new elementor-align-center elementor-widget elementor-widget-button" data-id="b162ba2" data-element_type="widget" data-widget_type="button.default">
                                  <div class="elementor-widget-container">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section class="elementor-section elementor-top-section elementor-element elementor-element-97c98c3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="97c98c3" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <div class="elementor-container elementor-column-gap-default">
                      <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-9c2e5d8" data-id="9c2e5d8" data-element_type="column">
                          <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-e6b545d elementor-widget elementor-widget-image" data-id="e6b545d" data-element_type="widget" data-widget_type="image.default">
                                  <div class="elementor-widget-container">
                                      <a href="https://larkfunding.com/">
        <img width="138" height="61" src="@/assets/images/footer-logo.png" class="attachment-full size-full wp-image-4319" alt="" />								</a>
                                  </div>
                              </div>
                              <!-- <div class="elementor-element elementor-element-bb189b8 elementor-widget elementor-widget-heading" data-id="bb189b8" data-element_type="widget" data-widget_type="heading.default">
                                  <div class="elementor-widget-container">
                                      <h4 class="elementor-heading-title elementor-size-default"><a href="https://larkfunding.com/contact-us/">Contact us</a></h4>
                                  </div>
                              </div>
                              <div class="elementor-element elementor-element-d63f7ec elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="d63f7ec" data-element_type="widget" data-widget_type="icon-list.default">
                                  <div class="elementor-widget-container">
                                      <ul class="elementor-icon-list-items">
                                          <li class="elementor-icon-list-item">
                                              <a href="mailto:servicecenter@starfirm.com">

                <span class="elementor-icon-list-text">servicecenter@starfirm.com</span>
                </a>
                                          </li>
                                      </ul>
                                  </div>
                              </div>
                              <div class="elementor-element elementor-element-e5ebcf3 elementor-widget elementor-widget-text-editor" data-id="e5ebcf3" data-element_type="widget" data-widget_type="text-editor.default">
                                  <div class="elementor-widget-container">
                                      Help Center </div>
                              </div> -->
                              <div v-if="false" class="elementor-element elementor-element-2c2c7cd elementor-widget elementor-widget-text-editor" data-id="2c2c7cd" data-element_type="widget" data-widget_type="text-editor.default">
                                  <div class="elementor-widget-container">
                                      Follow Us On </div>
                              </div>
                              <div v-if="false" class="elementor-element elementor-element-0cb039f e-grid-align-left e-grid-align-mobile-left elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons" data-id="0cb039f" data-element_type="widget" data-widget_type="social-icons.default">
                                  <div class="elementor-widget-container">
                                      <div class="elementor-social-icons-wrapper elementor-grid">
                                          <span class="elementor-grid-item">
    <a class="elementor-icon elementor-social-icon elementor-social-icon-youtube elementor-repeater-item-4f0ed87" href="https://www.youtube.com/@LarkFunding" target="_blank">
      <span class="elementor-screen-only">Youtube</span>
                                          <i class="fab fa-youtube"></i> </a>
                                          </span>
                                          <span class="elementor-grid-item">
    <a class="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-566b0f6" href="https://www.instagram.com/larkfunding/" target="_blank">
      <span class="elementor-screen-only">Instagram</span>
                                          <i class="fab fa-instagram"></i> </a>
                                          </span>
                                          <span class="elementor-grid-item">
    <a class="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-b069122" href="https://twitter.com/larkfunding" target="_blank">
      <span class="elementor-screen-only">Twitter</span>
                                          <i class="fab fa-twitter"></i> </a>
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-bdd1318" data-id="bdd1318" data-element_type="column">
                          <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-507dee8 elementor-widget elementor-widget-heading" data-id="507dee8" data-element_type="widget" data-widget_type="heading.default">
                                  <div class="elementor-widget-container">
                                      <h4 class="elementor-heading-title elementor-size-default">Useful Links</h4>
                                  </div>
                              </div>
                              <div class="elementor-element elementor-element-00e988b uael-nav-menu__breakpoint-none uael-nav-menu__align-left uael-submenu-icon-arrow uael-link-redirect-child elementor-widget elementor-widget-uael-nav-menu" data-id="00e988b" data-element_type="widget"
                                  data-widget_type="uael-nav-menu.default">
                                  <div class="elementor-widget-container">
                                      <div class="uael-nav-menu uael-layout-vertical uael-nav-menu-layout" data-layout="vertical">
                                          <div role="button" class="uael-nav-menu__toggle elementor-clickable">
                                              <span class="screen-reader-text">Main Menu</span>
                                              <div class="uael-nav-menu-icon">
                                              </div>
                                          </div>
                                          <nav class="uael-nav-menu__layout-vertical uael-nav-menu__submenu-arrow" data-toggle-icon="" data-close-icon="" data-full-width="">
                                              <ul id="menu-1-00e988b" class="uael-nav-menu">
                                                  <li id="menu-item-4340" class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a href="https://www.starfinland.com/#/pages/auth/login" class="uael-menu-item">Dashboard Login</a></li>
                                                  <li id="menu-item-4349" class="menu-item menu-item-type-custom menu-item-object-custom parent uael-creative-menu"><a href="https://www.starfinland.com" class="uael-menu-item">Take Our Evaluation</a></li>
                                                  <li id="menu-item-4342" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/1-stage-evaluation/" class="uael-menu-item">1-Stage Program</a></li>
                                                  <li id="menu-item-4343" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/2-stage-evaluation/" class="uael-menu-item">2-Stage Program</a></li>
                                                  <li id="menu-item-4346" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/faq/" class="uael-menu-item">FAQ</a></li>
                                                  <li id="menu-item-4348" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/terms-of-use/" class="uael-menu-item">Terms of use</a></li>
                                                  <li id="menu-item-4347" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/refund-policy/" class="uael-menu-item">Refund Policy</a></li>
                                                  <li id="menu-item-6235" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/aml-guidelines/" class="uael-menu-item">Anti Money Laundering</a></li>
                                                  <li id="menu-item-4345" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/contact-us/" class="uael-menu-item">Contact Us</a></li>
                                              </ul>
                                          </nav>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-5fd7cdd" data-id="5fd7cdd" data-element_type="column">
                          <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-2aa6375 elementor-widget elementor-widget-heading" data-id="2aa6375" data-element_type="widget" data-widget_type="heading.default">
                                  <div class="elementor-widget-container">
                                      <h4 class="elementor-heading-title elementor-size-default">Legal</h4>
                                  </div>
                              </div>
                              <div class="elementor-element elementor-element-3f25b50 uael-nav-menu__breakpoint-none uael-nav-menu__align-left uael-submenu-icon-arrow uael-link-redirect-child elementor-widget elementor-widget-uael-nav-menu" data-id="3f25b50" data-element_type="widget"
                                  data-widget_type="uael-nav-menu.default">
                                  <div class="elementor-widget-container">
                                      <div class="uael-nav-menu uael-layout-vertical uael-nav-menu-layout" data-layout="vertical">
                                          <div role="button" class="uael-nav-menu__toggle elementor-clickable">
                                              <span class="screen-reader-text">Main Menu</span>
                                              <div class="uael-nav-menu-icon">
                                              </div>
                                          </div>
                                          <nav class="uael-nav-menu__layout-vertical uael-nav-menu__submenu-arrow" data-toggle-icon="" data-close-icon="" data-full-width="">
                                              <ul id="menu-1-3f25b50" class="uael-nav-menu">
                                                  <li id="menu-item-2111" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/refund-policy/" class="uael-menu-item">Refund Policy</a></li>
                                                  <li id="menu-item-2210" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/terms-of-use/" class="uael-menu-item">Terms of Use</a></li>
                                                  <li id="menu-item-3045" class="menu-item menu-item-type-post_type menu-item-object-page parent uael-creative-menu"><a href="/aml-guidelines/" class="uael-menu-item">Anti Money Laundering</a></li>
                                              </ul>
                                          </nav>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-3b7468d" data-id="3b7468d" data-element_type="column">
                          <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-cc28254 elementor-widget elementor-widget-heading" data-id="cc28254" data-element_type="widget" data-widget_type="heading.default">
                                  <div class="elementor-widget-container">
                                      <h4 class="elementor-heading-title elementor-size-default">Stay up to date</h4>
                                  </div>
                              </div>
                              <div class="elementor-element elementor-element-8e28a14 elementor-widget elementor-widget-text-editor" data-id="8e28a14" data-element_type="widget" data-widget_type="text-editor.default">
                                  <div class="elementor-widget-container">
                                      Join our newsletter to stay up to date on our latest promos, giveaways and why STARFI is the prop firm you should get funded with. </div>
                              </div>
                              <div class="elementor-element elementor-element-01a6d2a news-form uael-cf7-style-box uael-cf7-input-size-sm uael-cf7-button-left uael-cf7-btn-size-sm uael-cf7-highlight-style-default elementor-widget elementor-widget-uael-cf7-styler" data-id="01a6d2a"
                                  data-element_type="widget" data-widget_type="uael-cf7-styler.default">
                                  <div class="elementor-widget-container">
                                      <div class="uael-cf7-container">
                                          <div class="uael-cf7 uael-cf7-style elementor-clickable">

                                              <div class="wpcf7 no-js" id="wpcf7-f215-o1" lang="en-US" dir="ltr">
                                                  <div class="screen-reader-response">
                                                      <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                      <ul></ul>
                                                  </div>
                                                  <form action="" method="post" @submit="onSubmit" onsubmit="return false" class="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init">
                                                      <div style="display: none;">
                                                          <input type="hidden" name="_wpcf7" value="215" />
                                                          <input type="hidden" name="_wpcf7_version" value="5.9.8" />
                                                          <input type="hidden" name="_wpcf7_locale" value="en_US" />
                                                          <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f215-o1" />
                                                          <input type="hidden" name="_wpcf7_container_post" value="0" />
                                                          <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
                                                          <input type="hidden" name="_wpcf7_recaptcha_response" value="" />
                                                      </div>
                                                      <p><span class="wpcf7-form-control-wrap" data-name="your-email"><input size="40" maxlength="400" class="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Enter Your Email" value="" type="email" name="your-email"   v-model="email"/></span><input
                                                              class="wpcf7-form-control wpcf7-submit has-spinner" type="submit" value="Subscribe"/>
                                                              <span class="wpcf7-not-valid-tip" aria-hidden="true" v-if="tips">{{tips}}</span>
                                                      </p>
                                                      <p style="display: none !important;" class="akismet-fields-container" data-prefix="_wpcf7_ak_"><label>&#916;<textarea name="_wpcf7_ak_hp_textarea" cols="45" rows="8" maxlength="100"></textarea></label><input type="hidden" id="ak_js_1" name="_wpcf7_ak_js" value="38" />
                                                      </p><input type='hidden' class='wpcf7-pum' value='{"closepopup":false,"closedelay":0,"openpopup":false,"openpopup_id":0}' />
                                                      <div class="wpcf7-response-output" aria-hidden="true">You're on the list! Thanks for subscribing.</div>
                                                  </form>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section class="elementor-section elementor-top-section elementor-element elementor-element-4c20931 copyright-section elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4c20931" data-element_type="section"
                  data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <div class="elementor-container elementor-column-gap-default">
                      <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2037683" data-id="2037683" data-element_type="column">
                          <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-b0ba222 elementor-widget elementor-widget-text-editor" data-id="b0ba222" data-element_type="widget" data-widget_type="text-editor.default">
                                  <div class="elementor-widget-container">
                                      <p>All content published and distributed by STARFI Inc. and its affiliates (collectively referred to as the “Company”) is for general informational purposes only. The business address of STARFI Inc. is 3 Place Ville-Marie, Montréal, QC H3B 4W8, and the content provided is for general reference only. Any information provided by or contained within STARFI Inc. does not constitute investment advice, an offer or invitation to buy or sell securities, nor does it constitute a recommendation, endorsement, or sponsorship of any security, company, or fund. The information on this website is not intended for residents of any country or jurisdiction where such distribution or use would violate local laws or regulations. STARFI Inc. provides brokerage services, trading services, and accepts or holds investor funds. All program fees are used for operating costs, including but not limited to staff, technology, and other business-related expenses. STARFI itself engages in regulated activities and is authorized by the U.S. Securities and Exchange Commission. Our website is operated by STARFI Inc. All rights reserved.</p>
                                      <p> </p>
                                      <p>The accounts we provide are institutional trading accounts. The services offered are aimed at assessing professional skills. We encourage clients to conduct a comprehensive review of the program requirements before enrolling in any services. We provide specific investment advice, business consulting, analysis of investment opportunities, or any general recommendations on trading investment instruments. Trading in financial markets carries a high level of risk, and we advise against risking more than you can afford to lose.</p>
                                  </div>
                              </div>
                              <div class="elementor-element elementor-element-384ad72 elementor-widget elementor-widget-text-editor" data-id="384ad72" data-element_type="widget" data-widget_type="text-editor.default">
                                  <div class="elementor-widget-container">
                                      <p>© Copyright 2024 STARFI</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <section class="elementor-section elementor-top-section elementor-element elementor-element-657ee8d sticky elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="657ee8d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <div class="elementor-container elementor-column-gap-no">
                      <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9eb3462" data-id="9eb3462" data-element_type="column">
                          <div class="elementor-widget-wrap elementor-element-populated">
                              <div class="elementor-element elementor-element-6517963 elementor-widget elementor-widget-image" data-id="6517963" data-element_type="widget" data-widget_type="image.default">
                                  
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div>
      </div>
  </footer>
</template>

<script setup>
import { error } from "jquery";
import { onMounted, ref } from "vue";

onMounted(() => {

  document.getElementById("ak_js_1").setAttribute("value", (new Date()).getTime());
  setTimeout(()=>{
    require('@/assets/js/script.min.js')
  })
})
const email = ref()
const tips = ref()
const emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
const onSubmit = () =>{
    console.log(email.value)
    if(email.value){
        if(!emailReg.test(email.value)){
            tips.value = 'Please enter an email address.'
        } else {
            tips.value = null;
            $('.news-form .wpcf7-form').removeClass('init').addClass('sent')
        }
    }else{
        tips.value = 'Please fill out this field.'
    }
}
</script>
<style scoped>

.newslatter-form .wpcf7-form p {
    position: relative;
}
.newslatter-form .wpcf7-form .wpcf7-submit{
    position: absolute;
    top: 0;
    right: 0;
}
.newslatter-form form.wpcf7-form.invalid .wpcf7-response-output {
    display: none;
}
.wpcf7-response-output {
    text-align: center;
}

.wpcf7-spinner {
    position: absolute;
    right: 0;
    bottom: -30px;
}

.sticky {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 99;
}

.faq_tab .htmega-tab-area {
    align-items: flex-start;
}

.news-form form.wpcf7-form {
                position: relative;
            }

            .news-form .wpcf7-submit {
                background: url("@/assets/images/icons/subscribe-arrow.svg") no-repeat, linear-gradient(91.83deg, #FAD24B 0%, #E6920F 100%) !important;
                width: 50px !important;
                height: 50px !important;
                background-position: center !important;
                position: absolute;
                top: 4px;
                right: 4px;
            }

            .news-form .wpcf7-submit:hover {
                opacity: .8;
            }

            .news-form .wpcf7-form.invalid .wpcf7-response-output {
                display: none;
            }

            .i-box .elementor-image-box-wrapper figure.elementor-image-box-img {
                width: 31px !important;
                min-width: 31px;
            }

            .recent-box .elementor-column-gap-narrow {
                justify-content: center;
            }

</style>